
		(function (root) {
			var $IMA = {};
			root.$IMA = root.$IMA || {};
			$IMA = root.$IMA;
			$IMA.i18n = $IMA.i18n || {};
			
$IMA.i18n['VerifyPinAndPay'] = {
  phoneNumberLabel: function(d) { return "Telefonní číslo"; },
  phoneNumberPlaceholder: function(d) { return "Zadejte tel. číslo"; },
  phoneNumberError: function(d) { return "Neplatné telefonní číslo"; },
  pinLabel: function(d) { return "PIN"; },
  pinPlaceholder: function(d) { return "Zadejte PIN"; },
  pinError: function(d) { return "Neplatný PIN"; },
  submit: function(d) { return "Potvrdit a zveřejnit"; },
  unknownError: function(d) { return "Zadané telefonní číslo nebo PIN jsou nesprávné"; }
};
			return $IMA.i18n;
		})(typeof window !== 'undefined' && window !== null ? window : global);
	