import React, { useCallback, useState } from 'react'
import { useLocalize } from 'app/base/componentHelpers'
import { Input, Button } from '@sznds/react'
import { Validation } from '@inzeraty/helpers'
import PAYMENT_STATE_KEYS from '../../PaymentStateKeys'
import select from 'ima-plugin-select'
import PropTypes from 'prop-types'
import { PAYMENTS_CONSTANTS } from 'app/model/payment/PaymentConstants'

import './VerifyPinAndPay.less'
import './VerifyPinAndPayCS.json'
import '../../PaymentViewCS.json'

const CLASSNAME = 'c-verify-and-pay'

const VerifyPinAndPay = (props) => {
	const localize = useLocalize()

	const { handleSMSPay, loading, isDraft } = props

	const [phoneNumberValue, setPhoneNumberValue] = useState('')
	const [pinValue, setPinValue] = useState('')

	const [phoneNumberError, setPhoneNumberError] = useState(false)
	const [pinError, setPinError] = useState(false)

	const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('')
	const [pinErrorMessage, setPinErrorMessage] = useState('')
	const [formErrorMessage, setFormErrorMessage] = useState('')

	const clearPhoneNumberErrorAndMessage = () => {
		setPhoneNumberError(false)
		setPhoneNumberErrorMessage('')
	}

	const clearPinErrorAndMessage = () => {
		setPinError(false)
		setPinErrorMessage('')
	}

	const setPhoneNumberErrorAndMessage = (errorMessage) => {
		setPhoneNumberError(true)
		setPhoneNumberErrorMessage(errorMessage)
	}

	const setPinErrorAndMessage = (errorMessage) => {
		setPinError(true)
		setPinErrorMessage(errorMessage)
	}

	const handlePhoneNumberChange = useCallback((event) => {
		setPhoneNumberValue(event.target.value)
		clearPhoneNumberErrorAndMessage()
	}, [])

	const handlePinChange = useCallback((event) => {
		setPinValue(event.target.value)
		clearPinErrorAndMessage()
	}, [])

	const handleSMSPayError = useCallback(
		(errors = []) => {
			const [firstError = {}] = errors
			const { error_code: errorCode } = firstError

			if (errorCode === PAYMENTS_CONSTANTS.ERROR.VIN_DUPLICATION) {
				setFormErrorMessage(localize('PaymentView.vinDuplicationError'))
			} else {
				setPhoneNumberError(true)
				setPinError(true)

				setFormErrorMessage(localize('VerifyPinAndPay.unknownError'))
			}
		},
		[localize]
	)

	const handleSMSPayFormSubmit = useCallback(
		(event) => {
			event.preventDefault()

			let isFormValidationError = false

			clearPhoneNumberErrorAndMessage()
			clearPinErrorAndMessage()
			setFormErrorMessage('')

			if (!Validation.checkPhone(phoneNumberValue)) {
				isFormValidationError = true

				setPhoneNumberErrorAndMessage(localize('VerifyPinAndPay.phoneNumberError'))
			}

			if (!pinValue) {
				isFormValidationError = true

				setPinErrorAndMessage(localize('VerifyPinAndPay.pinError'))
			}

			if (!isFormValidationError) {
				handleSMSPay(phoneNumberValue, pinValue, handleSMSPayError)
			}
		},
		[localize, phoneNumberValue, pinValue, handleSMSPay, handleSMSPayError]
	)

	const renderError = (cssClassName, errorMessage) => {
		if (!errorMessage) return null

		return (
			<div className={cssClassName}>
				<p>{errorMessage}</p>
			</div>
		)
	}

	const renderPhoneNumberError = () => {
		return renderError(`${CLASSNAME}__input-error`, phoneNumberErrorMessage)
	}

	const renderPinError = () => {
		return renderError(`${CLASSNAME}__input-error`, pinErrorMessage)
	}

	const renderFormErrorsMobile = () => {
		return renderError(`${CLASSNAME}__form-error-mobile`, formErrorMessage)
	}

	const renderFormErrorsDesktop = () => {
		return renderError(`${CLASSNAME}__form-error-desktop`, formErrorMessage)
	}

	return (
		<div className={CLASSNAME}>
			<form onSubmit={handleSMSPayFormSubmit} className={`${CLASSNAME}__form`}>
				<div>
					<label>
						<span className={`${CLASSNAME}__label`}>
							{localize('VerifyPinAndPay.phoneNumberLabel')}
						</span>
						<Input
							className={`${CLASSNAME}__input`}
							error={phoneNumberError}
							placeholder={localize('VerifyPinAndPay.phoneNumberPlaceholder')}
							value={phoneNumberValue}
							type='tel'
							onChange={handlePhoneNumberChange}
							aria-required={true}
							data-e2e='payment-verify-phone-number-input'
						/>
					</label>
					{renderPhoneNumberError()}
				</div>

				<div>
					<label>
						<span className={`${CLASSNAME}__label`}>{localize('VerifyPinAndPay.pinLabel')}</span>
						<Input
							className={`${CLASSNAME}__input`}
							error={pinError}
							placeholder={localize('VerifyPinAndPay.pinPlaceholder')}
							value={pinValue}
							onChange={handlePinChange}
							aria-required={true}
							data-e2e='payment-verify-pin-input'
						/>
					</label>
					{renderPinError()}
				</div>

				{renderFormErrorsMobile()}
				<Button
					className={`${CLASSNAME}__submit`}
					primary={true}
					loading={loading}
					text={localize('VerifyPinAndPay.submit')}
					type='submit'
					data-e2e='payment-verify-pin-submit'
					disabled={loading || isDraft}
				/>
			</form>
			{renderFormErrorsDesktop()}
		</div>
	)
}

const selectors = (state) => ({
	handleSMSPay: state[PAYMENT_STATE_KEYS.HANDLE_SMS_PAYMENT],
	loading: state[PAYMENT_STATE_KEYS.LOADING]
})

VerifyPinAndPay.propTypes = {
	handleSMSPay: PropTypes.func,
	loading: PropTypes.bool,
	isDraft: PropTypes.bool
}

export default React.memo(select(selectors)(VerifyPinAndPay))
